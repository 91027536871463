import React, {
  Fragment,
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import SockJS from "sockjs-client";
import { Client } from "@stomp/stompjs";
import { RootState } from "common/rootReducer";
import { SocketOrderInfo, SocketResponse } from "./socketModels";
import {
  getCurrentOrderDetail,
  getOrderDetail,
  getActiveOrderRequest,
  getKDSOrderDetails,
} from "common/features/order/orderActions";
import { useNotificationService } from "hooks/useNotificationService";
import { toast } from "react-toastify";
import {
  CURRENT_CHECKIN_RESERVATION_ID,
  ORDER_STATUS_CODES,
} from "common/constants";
import { getBooking } from "common/features/checkIn/checkInActions";
import { refreshRestaurantMenuDetails } from "common/features/restaurant/restaurantAction";
import { OrderType } from "common/features/restaurant/restaurantModels";

interface SocketHandlerProps {
  children: ReactNode;
  fallback?: ReactElement;
}

export default function SocketHandler({
  children,
  fallback,
}: SocketHandlerProps) {
  const dispatch = useDispatch();
  const ws = useRef<any>(null);
  const toastId = React.useRef<any>(null);
  const [socketStarted, setSocketStarted] = useState(false);
  const [isPaused, setPause] = useState(false);
  const user = useSelector((state: RootState) => state.auth.user);
  // const currentOrderDetail = useSelector(
  //   (state: RootState) => state.order.currentOrderDetail
  // );

  const [showOrderNotification] = useNotificationService();

  const notify = (message: string) =>
    (toastId.current = toast.success(message, {
      position: toast.POSITION.TOP_CENTER,
    }));
  const dismissAllNotifications = () => toast.dismiss();

  const currentRestaurantDetail = useSelector(
    (state: RootState) => state.restaurant.currentRestaurantDetail
  );

  const dineInOrderTypeId = useMemo(() => {
    if (currentRestaurantDetail.orderTypes) {
      return (
        currentRestaurantDetail.orderTypes.find(
          (orderType: OrderType) => orderType.typeGroup == "D"
        )?.id || ""
      );
    }
    return "";
  }, [currentRestaurantDetail]);

  useEffect(() => {
    if (
      !socketStarted &&
      user &&
      user.id &&
      currentRestaurantDetail.id.length > 0
    ) {
      setSocketStarted(true);
      const stompClient = new Client({
        webSocketFactory: () => {
          const serverUrl = process.env.REACT_APP_API_ENDPOINT + "/socket";
          const ws = new SockJS(serverUrl);
          return ws;
        },
        onConnect: (frame) => {
          stompClient.subscribe("/customer/" + user.id, (message) => {
            if (message.body) {
              socketMessageHandler(JSON.parse(message.body) as SocketResponse);
            }
          });
          stompClient.subscribe("/customer/all", (message) => {
            // Menu Update and CheckIn Status Updation
            // TABLE_UPDATED
            if (message.body) {
              socketMessageHandler(JSON.parse(message.body) as SocketResponse);
            }
          });
        },
        onDisconnect: (receipt) => {
         // console.log("Disconnect:", receipt);
        },
        debug: function (str) {
          // console.log('Debug:', str);
        },
        onStompError: function (frame) {
          //console.log("Broker reported error: " + frame.headers["message"]);
          // console.log("Additional details: " + frame.body);
        },
        reconnectDelay: 1000,
        // heartbeatIncoming: 400,
        // heartbeatOutgoing: 400,
      });
      stompClient.activate();
    }

    return () => {
      ws.current?.deactivate();
    };
  }, [user, currentRestaurantDetail, dineInOrderTypeId]);

  const socketMessageHandler = useCallback(
    (socketMessage: SocketResponse) => {
      const userID = localStorage.getItem("USER_ID");

      switch (socketMessage.messageType) {
        case "ORDER_UPDATE":
          const message = JSON.parse(
            socketMessage.messageData
          ) as SocketOrderInfo;

          if (message.orderTypeId == dineInOrderTypeId) {
            if (currentRestaurantDetail.dining === 3) {
              dispatch(
                getOrderDetail({ orderId: message.orderId, sortOrder: 1 })
              );
            } else if (currentRestaurantDetail.dining === 2) {
              dispatch(getKDSOrderDetails(message.orderId));
            }

            return;
          } else {
            dispatch(getOrderDetail({ orderId: message.orderId }));
          }
          // Refresh Order Tracking Page Data

          // AUto Refresh Floating Cart
          userID && dispatch(getActiveOrderRequest(userID));

          // Push Notification
          dismissAllNotifications();
          switch (message.orderStatus) {
            case ORDER_STATUS_CODES.MERCHANT_ACCEPTED:
              notify("Merchant Accepted Your Order");
              break;
            case ORDER_STATUS_CODES.ORDER_BEING_PREPARED:
              notify("Order is being prepared");
              break;
            case ORDER_STATUS_CODES.ORDER_READY:
              notify("Order is ready");
              break;
            case ORDER_STATUS_CODES.ORDER_PICKED_UP:
              notify("Order is PickedUp");
              break;
            case ORDER_STATUS_CODES.ORDER_CANCELLED_MERCHANT:
              notify("Order is Cancelled");
              break;
          }

          break;
        case "TABLE_UPDATED":
          // Refresh CheckIn Confirmation Page Data
          // Get Latest CheckIn from Browser Storage
          const resId = localStorage.getItem(CURRENT_CHECKIN_RESERVATION_ID);
          // console.log(resId);
          resId && dispatch(getBooking(resId));
          // Push Notification

          break;
        case "MENU_UPDATED":
          // Refresh Menu Page
          dispatch(refreshRestaurantMenuDetails());
          break;
        case "ORDER_ETA_UPDATE":
          notify("Order ETA has been updated");
          // Refresh Order Tracking Page Data
          dispatch(getCurrentOrderDetail());
          break;
        default:
          //console.log("UnHandled Socket Message");
          break;
      }
    },
    [currentRestaurantDetail, dineInOrderTypeId]
  );

  useEffect(() => {
    if (!ws.current) return;

    ws.current.onmessage = (e: any) => {
      if (isPaused) return;
      // const message = JSON.parse(e.data) as SocketResponse;
      // socketMessageHandler(message);
    };
  }, [isPaused]);

  return <Fragment>{children}</Fragment>;

  // if (props.component) {
  //   return props.component;
  // } else {
  //   return (
  //     <div>
  //       <div>Provide a Valid Component</div>
  //     </div>
  //   );
  // }
}
